<template>
  <tr class="offers-list__row" :class="{'offers-list__row--disabled': isLoading }" @mouseenter="shownRatioInput = item.id" @mouseleave="shownRatioInput = false">
    <td v-text="index + 1" />
    <td class="text-left text-caption">
      <Article class="offers-list__row--sku" :item="item" />
    </td>
    <td class="text-left text-caption" v-html="highlighted" @mouseenter="(event) => showPopover(event, item)" @mouseleave="hidePopover" />
    <td class="text-left text-caption" v-html="categoryName" />
    <td class="text-left text-caption" v-html="vendorName" />
    <td class="text-left text-caption" v-text="warehouseName" />
    <td class="text-left text-caption" v-text="item.warehouse_total_stock" />
    <td>
      <PriceWithUnitFactor :item="item" :over="shownRatioInput == item.id" @update="update" />
    </td>
    <td class="text-left text-caption" v-html="prices" />
    <td class="text-left text-caption">
      <GmidLink :gmid="item.gmid" v-if="item.gmid" />
    </td>
    <td class="text-right">
      <v-btn depressed small text width="71px" color="primary" @click="bind()" v-if="showBindWithItem" class="text-left offers-list__row--bind text-capitalize">
        Связать
      </v-btn>
      <v-btn depressed small text width="71px" @click="unbind()" v-else-if="showUnbindFromItem">
        <v-icon> mdi-check </v-icon>
      </v-btn>
      <v-btn epressed small text width="102px" color="primary" @click="merge()" v-else-if="showMergeWithItem" class="text-left offers-list__row--bind text-capitalize">
        Группировать
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
import hitHighlighter from '@/helpers/hitHighlighter';
import PriceWithUnitFactor from '@/components/common/Product/PriceWithUnitFactor';
import Article from '@/components/common/Product/Article';
import popover from '@/helpers/popover';
import { formatPrice } from '@/helpers/formatters';
import GmidLink from '@/components/common/GmidLink';

export default {
  components: {
    PriceWithUnitFactor,
    Article,
    GmidLink,
  },
  props: {
    item: { type: Object, required: true },
    index: { type: Number, required: true },
    hasBinded: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: false,
      popoverShow: false,
      shownRatioInput: false,
    };
  },
  computed: {
    ...mapState('canonical_products', ['product']),
    ...mapGetters('reference', ['getWarehouseRegionName']),
    canonicalUnit() {
      return this.product?.unit_localized ?? 'ед.';
    },
    gmid() {
      return this.product?.id?.toString();
    },
    isManyBinds() {
      return this.product.inventory_items?.length > 1 ?? false;
    },
    showBindWithItem() {
      return !this.item.gmid && !this.hasBinded;
    },
    showUnbindFromItem() {
      return this.item.gmid === this.gmid;
    },
    showMergeWithItem() {
      return this.item.gmid && this.gmid && this.gmid !== this.item.gmid;
    },
    highlighted() {
      return hitHighlighter(this.item.name || '', this.product.name || '');
    },
    categoryName() {
      return this.item.category?.name ?? '—';
    },
    vendorName() {
      return this.item.vendor?.name ?? '—';
    },
    warehouseName() {
      if (!this.item.warehouse_items || !this.item.warehouse_items.length) return '–';
      return this.getWarehouseRegionName(this.item.warehouse_items[0].warehouse_id);
    },
    prices() {
      return formatPrice(this.item.warehouse_items, 'price_per_unit_factor');
    },
  },
  methods: {
    ...mapMutations('inventory_items', ['UPDATE_GROUPED_ITEMS']),
    ...mapActions('inventory_items', ['BIND_PRODUCT_TO_CANONICAL', 'UNBIND_PRODUCT_FROM_CANONICAL']),
    ...mapActions('canonical_products', ['FETCH_PRODUCT', 'MERGE_PRODUCTS']),
    bind() {
      this.action('BIND_PRODUCT_TO_CANONICAL');
    },
    async unbind() {
      if (this.isManyBinds) {
        this.action('UNBIND_PRODUCT_FROM_CANONICAL');
        return;
      }
      if (await this.$root.$confirm('Отвязка товара', 'ВНИМАНИЕ! Вы пытаетесь разорвать последнюю связь! Вы уверены, что хотите это сделать?')) {
        this.action('UNBIND_PRODUCT_FROM_CANONICAL');
      }
    },
    async merge() {
      if (await this.$root.$confirm('Группировка товаров', 'Операция группировки товаров необратима! Вы уверены, что хотите группировать?')) {
        this.action('MERGE_PRODUCTS');
      }
    },
    async action(action = 'BIND_PRODUCT_TO_CANONICAL') {
      try {
        this.isLoading = true;
        const itemId = action === 'MERGE_PRODUCTS' ? this.item.gmid : this.item.id;
        const affected = await this[action]({ productId: this.product.id, itemId });
        this.$notify.showMessage({ message: `Изменено элеменов: ${affected.length}`, color: 'success' });
        this.FETCH_PRODUCT(this.product.id);
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
    showPopover(event, item) {
      popover.show(event, item, 'image_url');
    },
    hidePopover() {
      popover.hide();
    },
    update(item) {
      this.UPDATE_GROUPED_ITEMS([item]);
    },
  },

};
</script>

<style lang="scss" scoped>
.offers-list {
  &__row {
    transition: all 0.1 ease-in-out;
    background-color: hsla(0, 0%, 98%, 1);

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    &--sku {
      text-decoration: underline;
      cursor: pointer;
      color: hsl(0, 0%, 0%);

      &:hover {
        color: var(--v-primary-base);
      }
    }

    &--bind {
      border-radius: 6px;
      background-color: rgba(28, 78, 255, 0.08);
      font-weight: 400;
    }

    &--unbind {
      border-radius: 6px;
      background-color: rgba(0,0,0,0.03);
      font-weight: 400;
    }

    &--link {
      text-decoration: underline;
      text-decoration-color: inherit;
      text-decoration-style: solid;
    }
  }
}

</style>
