<template>
  <div class="product-prices">
    <v-row>
      <v-col class="py-0" cols="12">
        <h3 class="product-prices__title">
          Ручные розничные цены
        </h3>
      </v-col>
      <v-col class="py-0" cols="6" v-if="hasManualRetailPrices">
        <ProductPrice v-for="(item, index) in manualRetailPrices" :key="index" :item="item" />
      </v-col>
      <v-col class="py-0" :class="{ 'text-right': hasManualRetailPrices }" cols="6" align-self="end">
        <ProductPriceCreate :has-prices="hasManualRetailPrices" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ProductPrice from '@/components/CanonicalProduct/ProductPrices/ProductPrice';
import ProductPriceCreate from '@/components/CanonicalProduct/ProductPrices/ProductPriceCreate';

export default {
  components: {
    ProductPrice,
    ProductPriceCreate,
  },
  computed: {
    ...mapState('canonical_products', ['product', 'manualRetailPrices']),
    hasManualRetailPrices() {
      return Boolean(this.manualRetailPrices.length);
    },
  },
  created() {
    this.fetchManualRetailPrices();
  },
  methods: {
    ...mapActions('canonical_products', ['FETCH_MANUAL_RETAIL_PRICES']),
    async fetchManualRetailPrices() {
      try {
        await this.FETCH_MANUAL_RETAIL_PRICES(this.product.id);
      } catch (e) {
        if (!e.isAxiosError) throw e;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-prices {
  margin-top: 24px;
  margin-bottom: 24px;

  &__title {
    margin-bottom: 8px;
  }
}
</style>
