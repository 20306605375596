<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-toolbar dense elevation="0">
        <v-toolbar-title v-html="highlight(`Найти связи: ${title}`, title)" />
      </v-toolbar>
    </v-col>
    <v-col cols="12">
      <v-simple-table class="offers-list">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" width="2%">№</th>
              <th class="text-left" width="5%">Артикул</th>
              <th class="text-left" width="auto">Название товара</th>
              <th class="text-left" width="7%">Категория</th>
              <th class="text-left" width="7%">Бренд</th>
              <th class="text-left" width="7%">Склад</th>
              <th class="text-left" width="2%">Остаток</th>
              <th class="text-left" width="12%">Цена</th>
              <th class="text-left" width="7%">Цена/{{ canonicalUnit }} <PricePerUnitFactorTooltip /></th>
              <th class="text-left" width="5%" v-html="'Артикул&nbsp;ГМ'" />
              <th class="text-right" width="5%">Действия</th>
            </tr>
          </thead>
          <tbody>
            <OfferRow :item="item" :index="index" :has-binded="hasBinded" v-for="(item, index) in items" :key="item.id" />
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import hitHighlighter from '@/helpers/hitHighlighter';
import PricePerUnitFactorTooltip from '@/components/Item/PricePerUnitFactorTooltip';
import OfferRow from '@/components/CanonicalProduct/Offers/OfferRow';

export default {
  components: {
    PricePerUnitFactorTooltip,
    OfferRow,
  },
  props: {
    title: { type: String, required: true },
    items: { type: Array, required: true },
  },
  computed: {
    ...mapState('canonical_products', ['product']),
    canonicalUnit() {
      return this.product?.unit_localized ?? 'ед.';
    },
    hasBinded() {
      return this.items.filter((p) => (p.gmid && p.gmid === String(this.product.id))).length > 0;
    },
  },
  methods: {
    highlight(input, sample) {
      return hitHighlighter(input || '', sample || '');
    },
  },
};
</script>

<style lang="scss" scoped>

.sticky {
  position: sticky;
  top: 108px;
  background-color: hsl(0, 0%, 100%);
  z-index: 5;
  border-bottom: solid 1px;
  border-bottom-color: hsla(0, 0%, 92%, 1);
  padding-bottom: 16px;
}

</style>
