<template>
  <v-row>
    <v-col cols="12 sticky">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>
          <div v-text="title" />
          <div class="caption text--secondary" v-text="'Подходят ли эти товары поставщиков для установки связи?'" />
        </v-toolbar-title>
        <v-spacer />
        <OfferFilters />
      </v-toolbar>
    </v-col>
    <v-col class="my-15 text-center" cols="12" v-if="isLoading">
      <v-progress-circular size="32" indeterminate />
    </v-col>
    <template v-else>
      <template v-if="items">
        <v-col cols="12" v-for="item in items" :key="item[0]">
          <OffersGroup :title="item[0]" :items="item[1]" @update="FETCH_GROUPED_ITEMS(queryParams)" v-if="item[1].length" />
        </v-col>
      </template>
      <v-col class="d-flex justify-center" cols="12" v-else>Нет подходящих предложений</v-col>
    </template>
  </v-row>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters, mapActions } from 'vuex';

import OffersGroup from '@/components/CanonicalProduct/Offers/OffersGroup';
import OfferFilters from '@/components/CanonicalProduct/Offers/OfferFilters';

export default {
  components: {
    OffersGroup,
    OfferFilters,
  },
  props: {
    search: { type: String, default: '' },
  },
  data() {
    return {
      title: 'Фиды поставщиков',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('inventory_items', ['groupedItemsByOwners']),
    items() {
      if (!this.groupedItemsByOwners) return null;
      if (this.groupedItemsByOwners['ГдеМатериал']) delete this.groupedItemsByOwners['ГдеМатериал'];
      return Object.entries(this.groupedItemsByOwners);
    },
    queryParams() {
      return this.$route.query;
    },
  },
  watch: {
    queryParams: {
      immediate: true,
      deep: true,
      handler: debounce(async function (params) {
        this.isLoading = true;

        try {
          await this.FETCH_GROUPED_ITEMS(params);
          this.isLoading = false;
        } catch (e) {
          this.isLoading = e.isAxiosCancelError;
          if (!e.isAxiosCancelError) this.$notify.showErrorMessage(e.response);
          if (!e.isAxiosError && !e.isAxiosCancelError) throw e;
        }
      }, 300),
    },
  },
  mounted() {
    this.$router.replace({ query: { search: this.search } });
  },
  methods: {
    ...mapActions('inventory_items', ['FETCH_GROUPED_ITEMS']),
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky;
  top: 0px;
  background-color: hsl(0, 0%, 100%);
  z-index: 5;
  border-bottom: solid 1px;
  border-bottom-color: hsla(0, 0%, 92%, 1);
  padding-bottom: 16px;
}
</style>
