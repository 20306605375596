<template>
  <v-select v-model="_region" label="Регион" :items="regions" item-text="name" item-value="id" outlined dense hide-details />
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  props: {
    index: { type: Number, required: true },
    item: { type: Object, required: true },
    regions: { type: Array, required: true },
  },
  computed: {
    ...mapState('canonical_products', ['product']),
    _region: {
      get() {
        return this.item?.region ?? null;
      },
      set(val) {
        if (!this.item.id) return this.updateLocal(val);

        this.update(val);
      },
    },
  },
  methods: {
    ...mapActions('canonical_products', ['UPDATE_MANUAL_RETAIL_PRICE']),
    ...mapMutations('canonical_products', ['UPDATE_MANUAL_RETAIL_PRICE_BY_INDEX']),
    updateLocal(region) {
      this.UPDATE_MANUAL_RETAIL_PRICE_BY_INDEX({
        index: this.index,
        value: this.item.value,
        region,
      });
    },
    async update(region) {
      try {
        await this.UPDATE_MANUAL_RETAIL_PRICE({
          productId: this.product.id,
          manualRetailPriceId: this.item.id,
          region,
        });
      } catch (e) {
        if (!e.isAxiosError) throw e;
      }
    },
  },
};
</script>
