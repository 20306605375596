<template>
  <v-text-field class="product-price-amount" v-model="_price" type="number" label="Цена/меш." :suffix="suffix" outlined dense hide-details />
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapActions } from 'vuex';

import { getSymbolFromCurrency } from '@/helpers/currencySymbols';

export default {
  props: {
    item: { type: Object, required: true },
    regions: { type: Array, required: true },
  },
  computed: {
    ...mapState('canonical_products', ['product']),
    _price: {
      get() {
        return this.item?.value ?? null;
      },
      set: debounce(async function (val) {
        this.createOrUpdate(val);
      }, 700),
    },
    suffix() {
      const currency = this.regions.find((item) => item.id === this.item.region)?.currency;

      if (!currency) return null;

      return getSymbolFromCurrency(currency);
    },
  },
  methods: {
    ...mapActions('canonical_products', ['CREATE_MANUAL_RETAIL_PRICE', 'UPDATE_MANUAL_RETAIL_PRICE']),
    async createOrUpdate(value) {
      if (this.item.id) return this.update(value);

      this.create(value);
    },
    async create(value) {
      await this.CREATE_MANUAL_RETAIL_PRICE({
        productId: this.product.id,
        data: {
          region: this.item.region,
          value,
        },
      });
    },
    async update(value) {
      try {
        await this.UPDATE_MANUAL_RETAIL_PRICE({
          productId: this.product.id,
          manualRetailPriceId: this.item.id,
          value,
        });
      } catch (e) {
        if (!e.isAxiosError) throw e;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-price-amount {
  &::v-deep input {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
