<template>
  <div>
    <v-col class="my-15 text-center" cols="12" v-if="isLoading">
      <v-progress-circular size="32" indeterminate />
    </v-col>
    <v-col class="text-center" cols="12" v-else-if="hasError">
      <h1>404: продукт не найден</h1>
    </v-col>
    <template v-else>
      <v-col cols="12">
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <div class="mr-5">
              <v-img :src="product.image || '/box_one.png'" width="120" height="120" contain />
            </div>
            <h1 v-text="product.name" />
          </div>
          <div v-if="!product.archived">
            <a :href="parsaLink" target="_blank" rel="noopener noreferrer">
              Открыть в Парсе
            </a>
            <a style="text-decoration: none" :href="parsaLink" target="_blank" rel="noopener noreferrer">
              <v-icon small> mdi-open-in-new </v-icon>
            </a>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <ArchiveProductBtn :product-id="product.id" :archived="product.archived" :product-name="product.name" />
      </v-col>
      <v-col cols="12" v-if="product.archived">
        <ProductArchived />
      </v-col>
      <v-col cols="12" v-if="product.merged_with">
        <ProductIsMerged :id="product.merged_with" />
      </v-col>
      <v-col cols="12">
        <AutosyncSwitcher :product="product" />
      </v-col>
      <v-col cols="12">
        <h2>Связи в инвентаре</h2>
        <InventoryItems :product="product" @update="fetchProduct" />
      </v-col>
      <v-col class="d-flex justify-center align-center my-6" cols="12" v-if="!showOffersGroups">
        <v-btn class="text-caption text-capitalize" depressed color="primary" @click="showOffersGroups = true" v-text="'Найти связи в фидах поставщиков'" />
      </v-col>
      <v-col cols="12" v-show="showOffersGroups">
        <OffersGroups :search="product.name" />
      </v-col>
      <v-col cols="8">
        <h2>Связи в маркетплейсах</h2>
        <MarketplaceItems :product="product" :loading="isLoadingProduct" @update="fetchProduct" />
      </v-col>
      <v-col cols="12">
        <h2 class="mb-5">Редактирование</h2>
        <ProductRequiredAttributeValues :product="product" />
        <ProductForm :product="product" />
        <ProductPrices v-if="isSuperuser" />
        <ProductAttributesForm v-if="product.attribute_group" :product="product" />
      </v-col>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import { buildParsaProductUrl } from '@/helpers/formatters';

import InventoryItems from '@/components/CanonicalProduct/InventoryItems';
import MarketplaceItems from '@/components/CanonicalProduct/MarketplaceItems';
import AutosyncSwitcher from '@/components/CanonicalProduct/AutosyncSwitcher';
import ProductArchived from '@/components/CanonicalProduct/ProductArchived';
import ProductIsMerged from '@/components/CanonicalProduct/ProductIsMerged';
import ProductForm from '@/components/CanonicalProduct/ProductForm';
import ProductRequiredAttributeValues from '@/components/CanonicalProduct/ProductRequiredAttributeValues';
import ProductAttributesForm from '@/components/CanonicalProduct/ProductAttributesForm';
import ArchiveProductBtn from '@/components/CanonicalProduct/ArchiveProductBtn';
import OffersGroups from '@/components/CanonicalProduct/Offers/OffersGroups';
import ProductPrices from '@/components/CanonicalProduct/ProductPrices/ProductPrices';

export default {
  components: {
    AutosyncSwitcher,
    ProductArchived,
    ProductIsMerged,
    InventoryItems,
    MarketplaceItems,
    ProductForm,
    ProductRequiredAttributeValues,
    ProductAttributesForm,
    ArchiveProductBtn,
    OffersGroups,
    ProductPrices,
  },
  data() {
    return {
      isLoading: false,
      isLoadingProduct: false,
      hasError: false,
      showOffersGroups: false,
    };
  },
  metaInfo() {
    const productName = this.product?.name ?? '';
    return { title: `${productName} — Инвентарь` };
  },
  computed: {
    ...mapState('canonical_products', ['product']),
    ...mapGetters('auth', ['isSuperuser']),
    productId() {
      return this.$route.params.productId;
    },
    parsaLink() {
      return buildParsaProductUrl(this.productId);
    },
  },
  watch: {
    productId: {
      immediate: true,
      async handler() {
        this.hasError = false;
        this.isLoading = true;

        try {
          await Promise.all([
            this.fetchProduct(),
            this.FETCH_UNITS(),
            this.FETCH_WAREHOUSES(),
            this.FETCH_REGIONS(),
          ]);
        } catch (e) {
          this.hasError = true;
          if (!e.isAxiosError) throw e;
        } finally {
          this.isLoading = false;
        }
      },
    },
  },
  methods: {
    ...mapActions('canonical_products', ['FETCH_PRODUCT']),
    ...mapActions('reference', ['FETCH_UNITS', 'FETCH_WAREHOUSES', 'FETCH_REGIONS']),
    async fetchProduct() {
      this.isLoadingProduct = true;

      try {
        await this.FETCH_PRODUCT(this.productId);
      } catch (e) {
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoadingProduct = false;
      }
    },
  },
};
</script>
