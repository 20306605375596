<template>
  <div class="d-flex justify-end align-center">
    <QueryFilterObserver v-bind="filters.linkedProduct" />
    <QueryFilterObserver v-bind="filters.search" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import QueryFilterObserver from '@/components/QueryFilterObserver';

export default {
  components: {
    QueryFilterObserver,
  },
  computed: {
    ...mapState('canonical_products', ['product']),
    filters() {
      return {
        linkedProduct: {
          component: 'v-checkbox',
          name: 'linked_product',
          attrs: {
            label: 'Только связанные',
            'false-value': null,
            'true-value': this.product.id,
            'hide-details': true,
          },
        },
        search: {
          component: 'v-text-field',
          name: 'search',
          attrs: {
            dense: true,
            disabled: Boolean(this.$route.query.linked_product),
            'hide-details': true,
            'append-icon': 'mdi-magnify',
            class: ['search-field', 'ml-4'],
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  width: 250px;
  font-size: 12px;
}
</style>
