<template>
  <div class="d-flex align-start">
    <v-autocomplete v-model="select" :loading="isLoading" :items="categories" :search-input.sync="search" label="Категория" item-text="name" item-value="id" :allow-overflow="false" return-object outlined hide-no-data dense hide-details @blur="onBlur" />

    <v-btn class="mt-1 ml-3 text-decoration-none" :to="categoryEditPath" v-if="select" icon>
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    value: { type: Object, required: false, default: () => {} },
  },
  data() {
    return {
      isLoading: false,
      search: null,
    };
  },
  computed: {
    ...mapState('canonical_categories', ['categories']),
    select: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    categoryEditPath() {
      return `/categories/${this.select.id}`;
    },
    selectedName() {
      return typeof this.value === 'object' ? this.value.name : '';
    },
  },
  watch: {
    search: {
      handler: debounce(async function (val) {
        if (val && val !== this.select?.name) {
          this.querySelections({
            autocomplete: val,
          });
        }
      }, 500),
    },
  },
  mounted() {
    if (this.select) {
      this.querySelections({
        autocomplete: this.select.name,
      });
    }
  },
  methods: {
    ...mapActions('canonical_categories', ['FETCH_CATEGORIES']),
    async querySelections(params) {
      this.isLoading = true;

      try {
        await this.FETCH_CATEGORIES(params);
      } catch (e) {
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
    onBlur() {
      this.search = this.selectedName;
    },
  },
};
</script>
