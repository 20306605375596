<template>
  <v-alert color="orange" type="warning" v-if="requiredAttributes.length && productAttributeValues.length">
    <div v-for="(attribute, index) in requiredAttributes" :key="index">
      У товара нет параметра «{{ attribute.name }}»
    </div>
  </v-alert>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    product: { type: Object, required: true },
  },
  computed: {
    ...mapGetters('attribute_groups', ['attributes']),
    productAttributeValues() {
      return this.product.attribute_values.map((value) => value.attribute);
    },
    requiredAttributes() {
      return this.attributes.filter((attribute) => (
        !this.productAttributeValues.some(((value) => attribute.id === value.id))
      ));
    },
  },
};
</script>
