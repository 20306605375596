<template>
  <div class="autosync-switcher">
    <v-alert text type="info" v-if="product.autosync">
      <b>Автосинхронизация включена</b>
      <p>Если позиция в фиде изменится, то этот товар тоже изменится и в таком состоянии будет экспортирован дистрибьютору <br>Чтобы отключить ее, отредактируйте товар</p>
    </v-alert>
    <v-alert text type="error" color="error" v-else>
      <b>Автосинхронизация отключена</b>
      <p>Характеристики товаров не будут обновляться из фидов поставщиков, изменяться будет только «остаток», «цена» <br>Если включить синхронизацию, то отредактированные параметры будут потеряны</p>
      <v-btn depressed color="error" @click="autosync" :loading="isLoading">Включить</v-btn>
    </v-alert>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    product: { type: Object, required: true },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions('canonical_products', ['SET_AUTOSYNC']),
    async autosync() {
      try {
        this.isLoading = true;
        await this.SET_AUTOSYNC(this.product.id);
      } catch (e) {
        if (!e.isAxiosError) throw e;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
