<template>
  <v-btn color="success" @click="submit" :loading="isLoading" elevation="0" v-if="archived">Вернуть из архива</v-btn>
  <v-btn color="error" @click="submit" :loading="isLoading" elevation="0" v-else>Отправить в архив</v-btn>
</template>

<script>
export default {
  props: {
    productId: { type: Number, required: true },
    archived: { type: Boolean, required: true },
    productName: { type: String, required: true },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    confirmTitle() {
      return this.archived ? 'Возвращение товара из архива' : 'Отправка товара в архив';
    },
    confirmMessage() {
      return this.archived ? `Вы уверены, что хотите вернуть товар "${this.productName}" из архива?` : `Вы уверены, что хотите отправить товар "${this.productName}" в архив?`;
    },
    resultMessage() {
      return this.archived ? `Товар "${this.productName}" отправлен в архив` : `Товар "${this.productName}" возвращён из архива`;
    },
  },
  methods: {
    async submit() {
      if (await this.$root.$confirm(this.confirmTitle, this.confirmMessage)) {
        try {
          this.isLoading = true;

          const action = this.archived ? 'UNARCHIVE_PRODUCT' : 'ARCHIVE_PRODUCT';
          await this.$store.dispatch(`canonical_products/${action}`, this.productId);

          this.$notify.showMessage({ message: this.resultMessage, color: 'success' });
        } catch (e) {
          if (!e.isAxiosError) throw e;
          this.$notify.showErrorMessage(e.response);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
