<template>
  <div class="product-price">
    <ProductPriceRegion :item="item" :regions="_regions" :index="$vnode.key" />
    <ProductPriceAmount :item="item" :regions="_regions" />

    <ProductPriceRemove :item="item" :region-name="selectedRegion.name" />

    <div class="product-price__note" v-if="!item.id">
      Не сохранено. Для сохранения укажите цену.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ProductPriceRegion from '@/components/CanonicalProduct/ProductPrices/ProductPriceRegion';
import ProductPriceAmount from '@/components/CanonicalProduct/ProductPrices/ProductPriceAmount';
import ProductPriceRemove from '@/components/CanonicalProduct/ProductPrices/ProductPriceRemove';

export default {
  components: {
    ProductPriceRegion,
    ProductPriceAmount,
    ProductPriceRemove,
  },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    ...mapGetters('canonical_products', ['freeRegionsForManualRetailPrices']),
    ...mapGetters('reference', ['getRegionById']),
    selectedRegion() {
      return this.getRegionById(this.item.region);
    },
    _regions() {
      return [...this.freeRegionsForManualRetailPrices, this.selectedRegion];
    },
  },
};
</script>

<style lang="scss" scoped>
.product-price {
  display: grid;
  grid-template-columns: 1fr 150px 36px;
  grid-column-gap: 24px;

  &:not(:last-child) {
    margin-bottom: 14px;
  }

  &__note {
    margin-top: 5px;
    color: hsl(0, 0%, 27%);
    font-size: 12px;
    font-style: italic;
  }
}
</style>
