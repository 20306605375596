<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left" width="5%">Изображение</th>
          <th class="text-left" width="7%">Артикул</th>
          <th class="text-left" width="7%">Источник</th>
          <th class="text-left" width="auto">Название</th>
          <th class="text-left" width="10%">Производитель</th>
          <th class="text-left" width="5%">Категория</th>
          <th class="text-left" width="5%">Ед.измерения</th>
          <th class="text-left" width="12%">Цена</th>
          <th class="text-left" width="7%">Цена/{{ canonicalUnit }}</th>
          <th class="text-left" width="8%">Используется для автосинхронизации</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in product.inventory_items" :key="item.id" @mouseenter="shownRatioInput = item.id" @mouseleave="shownRatioInput = false">
          <td class="text-caption">
            <v-img class="my-1" :src="item.image_url || '/box_one.png'" width="64" height="64" contain />
          </td>
          <td class="text-caption">
            <router-link :to="getItemLink(item)">{{ item.sku }}</router-link>
          </td>
          <td class="text-caption">{{ ownerName(item) }}</td>
          <td class="text-caption">{{ item.name }}</td>
          <td class="text-caption">{{ vendorName(item) }}</td>
          <td class="text-caption">{{ categoryName(item) }}</td>
          <td class="text-caption">{{ item.unit_localized }}</td>
          <td>
            <PriceWithUnitFactor :item="item" :over="shownRatioInput == item.id" @update="val => update(val)" />
          </td>
          <td class="text-caption" v-html="getPricesPerUnitFactor(item)" />
          <td class="text-caption">
            {{ doesTheItemUsedForAutosync(item) }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { formatPrice, buildItemUrl } from '@/helpers/formatters';
import PriceWithUnitFactor from '@/components/common/Product/PriceWithUnitFactor';

export default {
  components: {
    PriceWithUnitFactor,
  },
  props: {
    product: { type: Object, required: true },
  },
  data() {
    return {
      shownRatioInput: false,
    };
  },
  computed: {
    canonicalUnit() {
      return this.product.unit_localized;
    },
  },
  methods: {
    doesTheItemUsedForAutosync(item) {
      return item.id === this.product.autosync_item.id ? 'Да' : 'Нет';
    },
    ownerName(item) {
      return item.owner?.name ?? '—';
    },
    categoryName(item) {
      return item.category?.name ?? '—';
    },
    vendorName(item) {
      return item.vendor?.name ?? '—';
    },
    getItemLink(item) {
      if (item) return buildItemUrl(item);
      return '';
    },
    shownRatio(item) {
      return (item?.unit !== this.product.unit && this.getPrice(item)) ?? false;
    },
    update() {
      this.$emit('update');
    },
    getPrice(item) {
      return formatPrice(item.warehouse_items);
    },
    getPricesPerUnitFactor(item) {
      return formatPrice(item.warehouse_items, 'price_per_unit_factor');
    },
  },
};
</script>

<style lang="scss" scoped>
.products-list {
  &__row {
    &--factor {
      width: 56px;
      text-align: center;
    }
  }
}
</style>
