<template>
  <v-row>
    <v-col cols="12">
      <h3>Параметры продукта</h3>
    </v-col>
    <v-col cols="6">
      <v-form ref="formAttributes" v-model="isValid" lazy-validation>
        <v-row v-for="item in form" :key="item.attribute.id" justify="space-between" align="center" no-gutters>
          <v-combobox v-if="item.attribute.is_multivalued" v-model="item.value" :label="item.attribute.name" :hint="item.attribute.is_digit ? 'Число, несколько значений' : 'Текст, несколько значений'" persistent-hint outlined dense multiple small-chips />
          <v-text-field v-else-if="!item.attribute.is_multivalued && item.attribute.is_digit" v-model="item.value[0]" :rules="[rules.required, rules.number]" :label="item.attribute.name" type="number" hint="Числовое значение" persistent-hint outlined dense />
          <v-text-field v-else v-model="item.value[0]" :rules="[rules.required]" :label="item.attribute.name" hint="Текстовое значение" persistent-hint outlined dense />
        </v-row>
      </v-form>
    </v-col>
    <v-col cols="12">
      <v-btn class="mr-4" color="success" :disabled="!isValid" @click="submit" :loading="isLoading" elevation="0">Сохранить</v-btn>
      <v-btn color="primary" @click="prefillForm" elevation="0" depressed>Сбросить форму</v-btn>
    </v-col>
    <v-col cols="7">
      <h3 class="mb-2">Добавить параметр</h3>
      <div class="d-flex align-center">
        <div class="mr-3">
          Существующий
        </div>
        <AddAttributeToInfomodelForm v-if="attributeGroup" :attribute-group-id="attributeGroup.id" />
      </div>
      <div class="d-flex align-center">
        <div class="mr-3">
          Создать новый
        </div>
        <CreateOrUpdateAttribute :attribute-group="attributeGroup" />
      </div>
    </v-col>
    <v-col cols="12">
      <router-link :to="`/infomodels/${product.attribute_group.id}`">Редактировать набор параметров для инфомодели продукта</router-link>
    </v-col>
  </v-row>
</template>

<script>
import get from 'lodash.get';
import { mapState, mapGetters, mapActions } from 'vuex';

import { getAttributesWithValues } from '@/helpers/productAttributes';

import AddAttributeToInfomodelForm from '@/components/Infomodel/AddAttributeToInfomodelForm';
import CreateOrUpdateAttribute from '@/components/Infomodel/CreateOrUpdateAttribute';

export default {
  components: {
    AddAttributeToInfomodelForm,
    CreateOrUpdateAttribute,
  },
  props: {
    product: { type: Object, required: true },
  },
  data() {
    return {
      isValid: false,
      isLoading: false,
      rules: {
        required: (v) => !!v || 'Обязательно поле',
        number: (v) => /^\d+$/.test(v) || 'Должно быть число',
      },
      form: [],
    };
  },
  computed: {
    ...mapState('attribute_groups', ['attributeGroup']),
    ...mapGetters('attribute_groups', ['attributes']),
    attributeValues() {
      return this.product.attribute_values;
    },
    attributeGroupId() {
      return get(this.product, 'attribute_group.id') || null;
    },
  },
  watch: {
    product: {
      immediate: true,
      deep: true,
      async handler() {
        if (!this.attributeGroupId) return;

        await this.FETCH_ATTRIBUTE_GROUP(this.attributeGroupId);
        this.prefillForm();

        await this.$nextTick();
        this.$refs.formAttributes.validate();
      },
    },
    attributes: {
      deep: true,
      handler() {
        this.prefillForm();
      },
    },
  },
  methods: {
    ...mapActions('attribute_groups', ['FETCH_ATTRIBUTE_GROUP']),
    ...mapActions('canonical_products', ['SET_ATTRIBUTE_VALUES']),
    async submit() {
      this.isLoading = true;

      const params = [];

      this.form.filter((item) => item.value.length === 1).forEach((item) => {
        params.push({ attribute: item.attribute.id, value: item.value[0] });
      });

      this.form.filter((a) => a.value.length > 1).forEach((item) => {
        item.value.forEach((attr) => {
          params.push({ attribute: item.attribute.id, value: attr });
        });
      });

      try {
        await this.SET_ATTRIBUTE_VALUES({
          productId: this.product.id,
          params,
        });
        this.$notify.showMessage({ message: 'Параметры продукта обновлены', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
    prefillForm() {
      this.form = getAttributesWithValues(this.attributes, this.attributeValues);
    },
  },
};
</script>
