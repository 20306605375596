<template>
  <v-alert text type="error" color="error">
    <b>Товар объединен!</b>
    <p>
      Товар объединён с другим каноничным товаром и больше не является каноничным!
      <router-link :to="`/products/${id}`">
        Перейти к новому каноничному товару
      </router-link>
    </p>
  </v-alert>
</template>

<script>
export default {
  props: {
    id: { type: Number, default: null },
  },
};
</script>
