<template>
  <v-form ref="form" v-model="isValid" lazy-validation>
    <v-row>
      <v-col cols="6">
        <div class="d-flex align-center mb-4">
          <div class="mr-5">
            <v-img :src="product.image || '/box_one.png'" width="64" height="64" contain />
          </div>
          <v-file-input v-model="image" label="Выберите изображение" :prepend-icon="null" hide-details outlined dense />
        </div>

        <v-text-field class="mb-4" v-model="name" :rules="requiredField" label="Название" outlined dense hide-details />

        <div class="d-flex align-start mb-4" v-if="hasAttributeGroup">
          <v-text-field :value="product.attribute_group.name" label="Инфомодель" outlined hide-details dense disabled />
          <v-btn class="mt-1 ml-3 text-decoration-none" :to="attributeGroupEditPath" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>

        <CategoryField class="mb-4" v-model="category" />
        <v-text-field class="mb-4" v-model="categoryName" label="Название категории" outlined dense hide-details />
        <VendorField class="mb-4" v-model="vendor" />
        <v-text-field class="mb-4" v-model="ean" :rules="eanRules" label="EAN" outlined dense hide-details />
        <v-select v-model="unit" :rules="requiredField" label="Единица измерения" :items="unitForSelect" outlined dense hide-details />
      </v-col>
      <v-col class="mt-3" cols="3">
        <v-text-field v-model="weight" label="Вес (упаковка)" suffix="кг" outlined dense />
        <v-text-field v-model="length" label="Длина (упаковка)" suffix="мм" outlined dense />
        <v-text-field v-model="width" label="Ширина (упаковка)" suffix="мм" outlined dense />
        <v-text-field v-model="height" label="Высота (упаковка)" suffix="мм" outlined dense />
        <v-text-field v-model="volume" label="Объём (упаковка)" suffix="м³" outlined dense />
      </v-col>
      <v-col class="mt-3" cols="3">
        <v-textarea v-model="description" label="Описание" outlined />
      </v-col>
      <v-col cols="12">
        <v-btn class="mr-4" color="success" @click="submit" :loading="isLoading" elevation="0">Сохранить</v-btn>
        <v-btn color="prirmary" @click="prefillForm" elevation="0" depressed>Сбросить форму</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import get from 'lodash.get';
import { mapActions, mapGetters } from 'vuex';

import { clearEmptyValues } from '@/helpers/clearData';

import CategoryField from '@/components/CanonicalProduct/ProductForm/CategoryField';
import VendorField from '@/components/CanonicalProduct/ProductForm/VendorField';

export default {
  components: {
    CategoryField,
    VendorField,
  },
  props: {
    product: { type: Object, required: true },
  },
  data() {
    return {
      isValid: true,
      isLoading: false,
      image: null,
      requiredField: [(v) => !!v || 'Обязательно поле'],
      name: '',
      categoryName: '',
      category: null,
      vendor: '',
      ean: '',
      eanRules: [
        (v) => (v ? (/^\d{12}$/.test(v) || 'ЕАН код должен соответствовать стандарту EAN-13') : true),
      ],
      unit: '',
      weight: null,
      length: null,
      width: null,
      height: null,
      volume: null,
      description: null,
    };
  },
  computed: {
    ...mapGetters('reference', ['unitForSelect']),
    mapDataFieldsToProduct() {
      return {
        name: 'name',
        category: 'category',
        categoryName: 'category_name',
        vendor: 'vendor',
        ean: 'ean',
        unit: 'unit',
        weight: 'weight',
        length: 'length',
        width: 'width',
        height: 'height',
        volume: 'volume',
        description: 'description',
      };
    },
    hasAttributeGroup() {
      return this.product && this.product.attribute_group;
    },
    attributeGroupEditPath() {
      return `/infomodels/${this.product.attribute_group.id}`;
    },
    vendorId() {
      return this.vendor ? this.vendor.id : null;
    },
  },
  watch: {
    product: {
      immediate: true,
      deep: true,
      handler() {
        this.prefillForm();
        this.$nextTick(() => this.$refs.form.validate());
      },
    },
  },
  methods: {
    ...mapActions('canonical_products', ['UPDATE_PRODUCT']),
    formDataFromParams() {
      const formData = new FormData();
      const params = clearEmptyValues({
        image: this.image,
        name: this.name,
        category: this.category && this.category.id,
        category_name: this.categoryName,
        vendor: this.vendorId,
        ean: this.ean,
        unit: this.unit,
        weight: this.weight,
        length: this.length,
        width: this.width,
        height: this.height,
        volume: this.volume,
        description: this.description,
      });

      Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
      });

      return formData;
    },
    async submit() {
      this.isLoading = true;

      try {
        await this.UPDATE_PRODUCT({
          productId: this.product.id,
          params: this.formDataFromParams(),
        });
        this.$notify.showMessage({ message: 'Информация о продукте обновлена', color: 'success' });
      } catch (e) {
        if (!e.isAxiosError) throw e;
        this.$notify.showErrorMessage(e.response);
      } finally {
        this.isLoading = false;
      }
    },
    prefillForm() {
      Object
        .entries(this.mapDataFieldsToProduct)
        .forEach(([dataField, productField]) => {
          this[dataField] = get(this.product, productField);
        });
      this.image = null;
    },
  },
};
</script>
