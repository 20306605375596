<template>
  <v-autocomplete v-model="select" :loading="isLoading" :items="canonicalVendors" :search-input.sync="search" cache-items label="Производитель" item-text="name" item-value="id" :allow-overflow="false" return-object outlined hide-no-data dense clearable hide-details @blur="onBlur" />
</template>

<script>
import debounce from 'lodash.debounce';
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    value: { type: Object, required: false, default: () => {} },
  },
  data() {
    return {
      isLoading: false,
      search: null,
    };
  },
  computed: {
    ...mapState('canonical_vendors', ['canonicalVendors']),
    select: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    selectedName() {
      return typeof this.value === 'object' ? this.value.name : '';
    },
  },
  watch: {
    search: {
      handler: debounce(async function (val) {
        if (val && val !== this.select?.name) {
          this.querySelections({
            autocomplete: val,
          });
        }
      }, 10),
    },
  },
  mounted() {
    if (this.select) {
      this.querySelections({
        autocomplete: this.select.name,
      });
    }
  },
  methods: {
    ...mapActions('canonical_vendors', ['FETCH_VENDORS']),
    async querySelections(params) {
      this.isLoading = true;

      try {
        await this.FETCH_VENDORS(params);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = e.isAxiosCancelError;
        if (!e.isAxiosError && !e.isAxiosCancelError) throw e;
      }
    },
    onBlur() {
      this.search = this.selectedName;
    },
  },
};
</script>
