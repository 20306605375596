<template>
  <div class="marketplace-items">
    <v-progress-linear class="marketplace-items__loader" height="3" indeterminate v-if="loading" />

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" width="17%">Артикул</th>
            <th class="text-left" width="20%">Маркетплейс</th>
            <th class="text-left" width="auto">Листинги</th>
            <th class="text-left" width="30%">Статус</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in product.marketplace_items" :key="item.id">
            <td>
              <FeedItemExternalId :item="item" @change="$emit('update')" />
            </td>
            <td class="text-caption">{{ marketName(item) }}</td>
            <td class="text-caption">{{ listingsRegions(item) }}</td>
            <td class="text-caption">{{ item.status_localized }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import FeedItemExternalId from '@/components/Feed/FeedItemsTable/FeedItemExternalId';

export default {
  components: {
    FeedItemExternalId,
  },
  props: {
    product: { type: Object, required: true },
    loading: { type: Boolean, default: false },
  },
  methods: {
    marketName(item) {
      return item.marketplace?.name ?? '—';
    },
    categoryName(item) {
      return item.category?.name ?? '—';
    },
    listingsRegions(item) {
      if (!item.listings) return '—';
      return item.listings.map((l) => l.region?.name).join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.marketplace-items {
  position: relative;

  &__loader {
    position: absolute;
    top: 46px;
    left: 0;
  }
}
</style>
