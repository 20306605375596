<template>
  <div class="product-price-create" v-if="freeRegionsForManualRetailPrices.length">
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <AppButton :text="text" theme="primary-light" v-bind="attrs" v-on="on" />
      </template>

      <v-list>
        <v-list-item class="product-price-create__item" v-for="region in freeRegionsForManualRetailPrices" :key="region.id" @click.native="create(region)">
          <v-list-item-title v-text="region.name" />
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import AppButton from '@/components/AppButton';

export default {
  components: {
    AppButton,
  },
  props: {
    hasPrices: { type: Boolean, default: false },
  },
  computed: {
    ...mapState('canonical_products', ['product']),
    ...mapGetters('canonical_products', ['freeRegionsForManualRetailPrices']),
    text() {
      if (!this.hasPrices) return 'Добавить регион';

      return 'Добавить ещё один регион';
    },
  },
  methods: {
    ...mapMutations('canonical_products', ['ADD_MANUAL_RETAIL_PRICE']),
    async create(region) {
      this.ADD_MANUAL_RETAIL_PRICE({
        region: region.id,
        value: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-price-create {
  &__item {
    cursor: pointer;
  }
}
</style>
