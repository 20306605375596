<template>
  <v-btn color="red" :loading="isRemoving" icon @click="beforeRemove">
    <v-icon>mdi-delete-outline</v-icon>
  </v-btn>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  props: {
    item: { type: Object, default: null },
    regionName: { type: String, default: null },
  },
  data() {
    return {
      isRemoving: false,
    };
  },
  computed: {
    ...mapState('canonical_products', ['product']),
  },
  methods: {
    ...mapActions('canonical_products', ['DELETE_MANUAL_RETAIL_PRICE']),
    ...mapMutations('canonical_products', ['REMOVE_MANUAL_RETAIL_PRICE']),
    beforeRemove() {
      if (this.item.id) return this.remove();

      this.removeLocal();
    },
    removeLocal() {
      this.REMOVE_MANUAL_RETAIL_PRICE(this.item.region);
    },
    async remove() {
      const confirmed = await this.$root.$confirm('Удаление', `Вы действительно хотите удалить цену для региона ${this.regionName}?`);

      if (!confirmed) return;

      this.isRemoving = true;

      try {
        await this.DELETE_MANUAL_RETAIL_PRICE({
          productId: this.product.id,
          manualRetailPriceId: this.item.id,
        });
      } catch (e) {
        if (!e.isAxiosError) throw e;
      } finally {
        this.isRemoving = false;
      }
    },
  },
};
</script>
